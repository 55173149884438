import React from 'react';
import './style.css';
import logo from './logo.png';
import logoWhite from './logo-white.png';
import logoWhiteYellow from './logo-white-yellow.png';

const Logo = ({ className }) => {
  return (
    <img src={ logo } className={ `${className} p-3` } />
  );
}

const FullWhiteLogo = ({ className }) => {
  return (
    <img src={ logoWhite } className={ `${className} p-3` } />
  );
}

const WhiteYellowLogo = ({ className }) => {
  return (
    <img src={ logoWhiteYellow } className={ `${className} p-3` } />
  );
}

const TextlessLogo = ({ className }) => {
  return (
    <img src={ logo } className={ `${className} p-3` } />
  );
}

export {
  Logo, TextlessLogo, WhiteYellowLogo, FullWhiteLogo
}