import React, { useEffect, useState } from 'react';
import api from '../../services/api';

export default function LoginInfo() {
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  
  useEffect(() => {
    getCompany();
    setUser(JSON.parse(localStorage.getItem('otimize-user')));
  }, []);
  
  async function getCompany() {
    // await api.get('/companies').then(response => {
    //   setCompany(response.data.company);
    // });
    setCompany({
        name: 'SP Geradores'
    });
  }

  return (
    <aside className='absolute top-0 left-1/2 w-full lg:max-w-screen-xl xl:max-w-screen-2xl 2xl:max-w-screen-2xl h-[7rem] -translate-x-2/4 flex flex-col justify-start items-end text-xs px-[1rem] 2xl:px-0 py-[0.5rem]'>
      <p>{ user.email }</p>
      <p>{ company.name }</p>
    </aside>
  )
}